
import './App.css';
import Main from './pages/main/Main';


function App() {

  return (
    <Main/>
  );
}

export default App;
